import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import setpointV2ApolloClient from '@/lib/appsync/setpointv2';
import getSetpointRecV2 from '@/graphql/setpoints/queries/getSetpointRecV2.graphql';
import postSetpointJobResponse from '@/graphql/workflow/mutations/postSetpointsJobResponse.graphql';
import getLatestSetpointRec from '@/graphql/setpoints/queries/get_latest_setpoint_rec.graphql';
import isOnline from 'is-online';
import localforage from '@/lib/localForage';
import workflowApolloClient from '@/lib/appsync/workflow';
import tasqsListModule from '@/store/modules/tasqsListModule';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'setpointV2Module',
  store,
})
class SetpointV2Module extends VuexModule {
  tasqSetpointData: any = {};

  tasqListPressureData: any = {};

  tasqMCFData: any = {};

  unitType: any = '-';

  currentWellSetpointData: any = null;

  uplift: any = '-';


  @Mutation
  setCurrentWellSetpointData(data): void {
    this.currentWellSetpointData = data;
  }

  @Mutation
  setUplift(data): void {
    this.uplift = data;
  }

  @Mutation
  setUnitType(data): void {
    this.unitType = data;
  }

  @Mutation
  setTasqSetpointData(data): void {
    this.tasqSetpointData = data;
  }

  @Mutation
  setTasqListPressureData(data): void {
    this.tasqListPressureData = data;
  }

  @Mutation
  setTasqMCFData(data): void {
    this.tasqMCFData = data;
  }



  @Action
  async getLatestSetpointRec({ nodeID }) {
	  try {
      const {
        data: {
          // @ts-ignore
          get_latest_setpoint_data: {Result: setpointData},
        },
        // eslint-disable-next-line no-tabs
      } = await workflowApolloClient.query({
        query: getLatestSetpointRec,
        variables: {
          NodeID: nodeID,
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      });

      console.log(JSON.parse(setpointData));
      if(JSON.parse(setpointData) && Object.keys(JSON.parse(setpointData)).length){
          this.setCurrentWellSetpointData(JSON.parse(setpointData))
      }

	  } catch (e) {
      this.setCurrentWellSetpointData(null)
		  console.log(e);
	  }
  }

  @Action
  async postSetpointJobResponse({ predictionID, workflowId, accepted }) {
	  try {
      const {
        data: {
          // @ts-ignore
          respond_to_setpoint_job: results,
        },
        // eslint-disable-next-line no-tabs
      } = await workflowApolloClient.mutate({
        mutation: postSetpointJobResponse,
        variables: {
          input: {
            WorkflowTaskID: workflowId,
            PredictionID: predictionID,
            Accepted: accepted,
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }

  @Action
  async getSetpointRecV2ForTasq({
    wellName,
    isOfflinePreloading = false,
  }) {
    let result: any = null;
    try {
      if (await isOnline()) {
        const { data: { get_setpoint_rec_v3: { data } } } : any = await setpointV2ApolloClient.query({
          query: getSetpointRecV2,
          variables: {
            operator: getConfigEnv('OPERATOR_LOWERCASED').charAt(0).toUpperCase() + getConfigEnv('OPERATOR_LOWERCASED').slice(1),
            nodeid: wellName,
          },
        });


        if (tasqsListModule.enablePreloading) {
        await localforage.setItem(`getSetpointRecV2ForTasq-${wellName}`, data);
        }

        result = data;
      } else {
        result = await localforage.getItem(`getSetpointRecV2ForTasq-${wellName}`);
      }

      if (!isOfflinePreloading && result) {
	  this.setTasqSetpointData(JSON.parse(JSON.parse(result)).setpoints);
	  this.setTasqListPressureData(JSON.parse(JSON.parse(result)).lift_pressure);
	  // bbl
	  if (JSON.parse(JSON.parse(result)).bbl != null) {
          this.setUplift(JSON.parse(JSON.parse(result)).uplift_bbl);
          this.setUnitType('bbl');
          this.setTasqMCFData(JSON.parse(JSON.parse(result)).bbl);
	  } else {
          this.setUplift(JSON.parse(JSON.parse(result)).uplift_mcf);
          this.setUnitType('mcf');
          this.setTasqMCFData(JSON.parse(JSON.parse(result)).mcf);
        }
	  }

      //   const parsedDataset = jsonParse(dataset);
      //   const { plot } = parsedDataset[0];
      //   const setpointData = {} as any;
      //   setpointData.labels = plot.dataset['Tubing Pressure'];
      //   setpointData.casing = plot.dataset['Casing Pressure'];
      //   setpointData.static = plot.dataset['Static Pressure'];
      //   setpointData.loadFactor = plot.dataset['Load Factor'];
      //   [, setpointData.mainData] = plot.dataset.Value;
      //   [,, setpointData.lowerData] = plot.dataset.Value;
      //   [setpointData.upperData] = plot.dataset.Value;
      //   setpointData.currentPoint = plot.dataset.Current;
      //   setpointData.recommendedPoint = plot.dataset.Recommend;
      //   setpointData.uplift = (plot.dataset.perc_uplift * 100).toFixed();

    //   this.setSetpointDataForTasq(setpointData);
    } catch (e) {
      console.error(e);
    }
  }
}

export default getModule(SetpointV2Module);
