/**
 * An alternative client for workflow
 */
 import AWSAppSyncClient from 'aws-appsync';
 import VueApollo from 'vue-apollo';
 import {
   isTokenExpired,
   refreshAccessToken,
   getAccessToken,
 } from '@/lib/auth';
 import localForage from '@/lib/localForage';
 import { getConfigEnv } from '@/utils/helpers';

 const client = new AWSAppSyncClient({
   url: getConfigEnv('SETPOINT_V2_ENDPOINT_URL'),
   region: getConfigEnv('APPSYNC_REGION'),
   auth: {
	 type: 'AMAZON_COGNITO_USER_POOLS',
	 jwtToken: async () => {
	   if (isTokenExpired()) {
		 await refreshAccessToken();
	   }
	   return getAccessToken();
	 },
   },
   offlineConfig: {
	 storage: localForage,
	 keyPrefix: 'setpointv2',
   },
 }, {
   defaultOptions: {
	 watchQuery: {
	   fetchPolicy: 'cache-and-network',
	 },
   },
 });

 const appSyncProvider = new VueApollo({
   defaultClient: client,
 });
 const setpointV2ApolloClient = appSyncProvider.defaultClient;

 export default setpointV2ApolloClient;
