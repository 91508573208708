import { email } from 'vee-validate/dist/rules';
import Component, { mixins } from 'vue-class-component';
import Vue from 'vue';
import metaDataModule from '@/store/modules/metaDataModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import workflowModule from '@/store/modules/workflowModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import accountModule from '@/store/modules/accountModule';
import gptModule from '@/store/modules/gptModule';
import setpointV2Module from '@/store/modules/setpointV2Module';
import { getConfigEnv, getISOStringWithLocalOffset, sleep } from '@/utils/helpers';
import axiosClient from '@/lib/rest/axiosClient';
import GptMixin from '@/components/gpt/GptMixin';
import uniqid from 'uniqid';

@Component
export default class TasqFeedbackMixin extends mixins(GptMixin) {
  isSavingData = false;

  showLinkSharePopup = false;

  showSetpointRecModal = false;

  hideBottomTodoList = false

  showPlungerRecModal = false;

  lastActionCateogryData: any = []

  lastActionTypesData: any = []

  showLastActionModal = false;

  showRcommendation = false;

  lastActionDetails: any = null;

  get feedbackStepNo() {
    return tasqFeedbackModule.stepper;
  }

  get currentViewType() {
    return this.$route.query.view;
  }

  get tasqListLevel() {
    return tasqsListModule.tasqListLevel.toLowerCase();
  }

  get producingTasq() {
    return assetsModule.activeTasq;
  }

  get currentWellType(): any {
    if (this.producingTasq) {
      return 'producing';
    }
    return this.$route.query.type;
  }

  get selectedForms() {
    return tasqFeedbackModule.selectedForms;
  }

  get tasq() {
    if (tasqsListModule.isBatchResponding) {
      if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (tasqsListModule.activeTasq) {
      return tasqsListModule.activeTasq as TasqJob;
    }
    // if (this.currentWellType === 'producing') {
    //   console.log( assetsModule.activeTasq);

    // }
    return assetsModule.activeTasq as TasqJob;
  }

  copyLink(type, wellName = '', tasqListLevel: any = 'tasq') {
    if (type === 'tasq') {
      navigator.clipboard.writeText(window.location.href);
    } else if (type === 'well') {
      const url = `${window.location.origin}/Tasqs/${encodeURI(wellName)}?type=producing&view=tasq`;
      navigator.clipboard.writeText(url);
    } else if (type === 'pad') {
      const url = `${window.location.origin}/Tasqs/${encodeURI(wellName)}?&view=padview`;
      navigator.clipboard.writeText(url);
    }

    this.showLinkSharePopup = false;
  }

  async handleFormResponse({ markAsComplete = false, isBatchMode = false, isGPTView = false, incrementStep = true }) {
    this.isSavingData = true;
    try {
      const jobFormResponsePayload: any = {
        predictionID: this.tasq.id,
        workflowTaskID: this.tasq.workflowTasqId,
        completeForToday: markAsComplete,
        closeTasq: this.tasq?.isManuallyAdded && markAsComplete ? true : false,
        formResponse: tasqFeedbackModule.selectedForms,
        nodeID: this.tasq.wellName,
        validationResponse: tasqFeedbackModule.validationResponse,
      };
      if (this.tasq.predictionType === 'Producing' || this.tasq.level === 'PAD') {
        // await this.postProducingWellForms();
      } else {
        await this.postTasqForms(jobFormResponsePayload);
        if (incrementStep) {
          if (isGPTView) {
            if (this.feedbackStepNo < 2) {
              tasqFeedbackModule.setStepper(this.feedbackStepNo + 1);
            }
          } else {
            tasqFeedbackModule.setStepper(this.feedbackStepNo + 1);
          }
        }
      }
      await tasqsListModule.getSubmittedForms();
    } catch (error) {
      this.$tasqAlert({
        title: 'Error',
        message: 'Saving actions failed!',
        type: 'error',
      });
    }
    this.isSavingData = false;
  }

  async postProducingWellForms() {
    if (this.feedbackStepNo === 1) {
      tasqFeedbackModule.setStepper(this.feedbackStepNo + 1);
    } else if (this.feedbackStepNo === 2) {
      const uniqueCategories = [...new Set(tasqFeedbackModule.selectedForms.map((form) => form.category))];
      uniqueCategories.forEach(async (category) => {
        const predictionID = await tasqActionsModule.addTasq({
          // eslint-disable-next-line max-len
          assetType: this.tasq.level,
          person: accountModule.user.email,
          well: this.tasq.wellName,
          comment: '',
          actions: tasqFeedbackModule.selectedForms.filter((form) => form.category === category),
          jobType: category,
        });

        await tasqsListModule.getRawTasq({ PredictionID: predictionID });
      });
      tasqFeedbackModule.resetAllData();
      this.$tasqAlert({
        title: 'Success',
        message: 'Action(s) recorded successfully.',
        type: 'success',
      });
    }
  }

  async postTasqForms(jobFormResponsePayload) {
    await metaDataModule.postJobFormsResponse(jobFormResponsePayload);
    gptModule.setOverviewHidden(true);
    this.$nextTick(() => {
      gptModule.setOverviewHidden(false);
    });

    // await tasqsListModule.getTasq({ PredictionID: this.tasq.id });
    // await this.getWellHistory()
  }

  async getWellHistory() {
    const promises: any = [];

    if (this.tasq.workflowTasqId != null) {
      promises.push(tasqsListModule.getTasqEventHistoryList(this.tasq.workflowTasqId));
    } else if (this.tasq.scheduledJobID != null) {
      promises.push(tasqsListModule.getTasqEventHistoryList(this.tasq.scheduledJobID));
    }

    promises.push(workflowModule.getWellHistory(this.tasq.wellName));

    Promise.all(promises).then(
      () => {
        // console.log('resolved handle step v1');
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.log('Error:');
        // eslint-disable-next-line no-console
        console.log(err);
      }
    );
  }

  async postBatchTasqForms(jobFormResponsePayload) {
    for (let i = 0; i < tasqsListModule.checkedTasqs.length; i++) {
      const checkedTasqDetails = tasqsListModule.tasqById(tasqsListModule.checkedTasqs[i]);
      jobFormResponsePayload.predictionID = checkedTasqDetails?.id;
      jobFormResponsePayload.nodeID = checkedTasqDetails?.wellName;
      jobFormResponsePayload.workflowTaskID = checkedTasqDetails?.workflowTasqId;
      jobFormResponsePayload.closeTasq = checkedTasqDetails?.isManuallyAdded;
      const newPayload = { ...jobFormResponsePayload };
      // eslint-disable-next-line no-await-in-loop
      await metaDataModule.postJobFormsResponse(newPayload);
      // eslint-disable-next-line no-await-in-loop
      await tasqsListModule.getRawTasq({
        PredictionID: tasqsListModule.checkedTasqs[i],
      });
    }
  }

  async updateSetpoints(value) {
    this.isSavingData = true;
    let message = '';
    this.showSetpointRecModal = false;

    try {
      let accepted = true;
      if (value === 'Reject') {
        accepted = false;
      }
      await setpointV2Module.postSetpointJobResponse({
        predictionID: this.tasq.id,
        workflowId: this.tasq.workflowTasqId,
        accepted: accepted,
      });
      tasqFeedbackModule.setResponseSelectionAction('NO');

      const jobFormResponsePayload = {
        predictionID: this.tasq.id,
        workflowTaskID: this.tasq.workflowTasqId,
        completeForToday: true,
        closeTasq: false,
        formResponse: [],
        nodeID: this.tasq.wellName,
        validationResponse: tasqFeedbackModule.validationResponse,
      };

      await metaDataModule.postJobFormsResponse(jobFormResponsePayload);
      await tasqsListModule.getSubmittedForms();
      let newTasq = await tasqsListModule.getTasq({
        PredictionID: this.tasq.id,
      });
      if (accepted) {
        message = 'Setpoint Job Accepted';


      } else {
        message = 'Setpoint Job Rejected';
      }

      const operatorDetails: any = this.operatorList.find((o) => o['appName'] === this.operatorName);

      const metaData = {
        body: message,
        wellMetaData: {
          ...(this.operatorName !== 'demo' && {
            operator_name: operatorDetails['operatorName'],
          }),
          ...(this.operatorName !== 'demo' && {
            operator_id: operatorDetails['operatorID'],
          }),
          nodeid: this.tasq.wellName,
          level: this.tasq && this.tasq.level ? this.tasq.level.toLowerCase() : 'well'
        },
      };

      await gptModule.postGptComment(metaData);
      value = '';
      this.$tasqAlert({
        title: 'Success',
        message: message,
        type: 'success',
      });
    } catch (error) {
      this.$tasqAlert({
        title: 'Error',
        message: 'Setpoint update failed! ',
        type: 'error',
      });
    }
    this.isSavingData = false;
  }

  async saveRecommendationFeedback({ comment, isGoodCatch, recommendationText,trackingID }) {
    this.showRcommendation = false;
    const filterPayload = {
      operator: getConfigEnv('OPERATOR_LOWERCASED'),
      node_name: this.tasq.wellName,
      node_level: this.tasq.level,
      value_type: 'label',
      source_type: 'recommendation',
    };

    await axiosClient.post('/response-store/', {
      ...filterPayload,
      time: new Date().toISOString(),
      tracking_id: trackingID,
      data: {
        comment: comment,
        isGoodCatch: isGoodCatch,
        recommendationText: recommendationText,
      },
    });
  }


  async userOpensRecommendations(trackingID) {

    try {
      await axiosClient.put(`/response-store/recommendations-usage?tracking_id=${trackingID}`);
    } catch (error) {
      console.log(error);
    }
  }


  get reassignUsers() {
    return accountModule.reassignmentList
      .map((i) => ({
        // @ts-ignore
        text: i.name,
        value: i.email,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }



  get pendingItems(){
    return gptModule.pendingTodoItems
  }


  get completeItems(){
    return gptModule.completedTodoItems
  }


  async updateTodoList(item) {

    try {
      gptModule.addItemToPendingTodoList(item)
      let hasCurrentUserAsAssignee = false;
      const newPayload = this.pendingItems.map((task) => {
        const assignees = [];

        this.reassignUsers.forEach((user) => {
          const atMention = '@' + user.text;
          if (task.text.includes(atMention)) {
            assignees.push(user.value);
          }
        });

        if (assignees.includes(accountModule.user.email)) {
          hasCurrentUserAsAssignee = true;
        }

        task.assignees = assignees;
        return task;
      });

      this.$emit('close');
      this.showLastActionModal = false;
      await workflowModule.postTodolist({
        activeList: [...newPayload],
        completedList: [...this.completeItems],
        nodeID: this.tasq.wellName,
        level: this.tasq.level,
      });

      gptModule.addWellsTodoAssignee({
        wellName: this.tasq.wellName,
        status: hasCurrentUserAsAssignee,
      });

    } catch (error) {
      console.log(error);
    }
    this.hideBottomTodoList = true
    this.$nextTick(() => {
      this.hideBottomTodoList = false
    })




    // tasqsListModule.setIsLoadingTasqs(true);
    // this.$nextTick(async () => {
    //   tasqsListModule.setIsLoadingTasqs(false);
    // });
  }

  async updateAiComment(payload) {
    try {
      console.log(payload);

      this.showLastActionModal = false;
      await sleep(1000);
      const data = {
        id: payload.id || this.lastActionDetails.id,
        body: payload.body || this.lastActionDetails.body,
        summary: payload.summary || this.lastActionDetails.summary,
        type: this.lastActionDetails.title,
        start_date: payload.startDate
          ? new Date(payload.startDate).toISOString()
          : this.lastActionDetails.start_date || this.lastActionDetails.date,
        end_date: payload.endDate ? new Date(payload.endDate).toISOString() : this.lastActionDetails.end_date,
        category: payload.category || this.lastActionDetails.category,
        sub_category:  payload.subCategory || this.lastActionDetails.subCategory,
        fixed_category:  payload.fixedCategory || this.lastActionDetails.fixedCategory,
        cause:  payload.cause || this.lastActionDetails.cause,
        tracking_id: this.lastActionDetails.trackingId && this.lastActionDetails.trackingId !== 'NaN'?this.lastActionDetails.trackingId : await uniqid()
      };



      const response = await axiosClient.post('/ai/vector', data);
      console.log('Response for', 'ai/vector first', ':', response.data);

      (data['date'] = payload.startDate ? getISOStringWithLocalOffset(payload.startDate) : this.lastActionDetails.date),
        // console.log(gptModule.defaultLastActions);

      gptModule.updateLastAction({ lastAction: this.lastActionDetails, action: data });
      gptModule.updateDefaultLastAction({ lastAction: this.lastActionDetails, action: data });


      if(data.type && data.type.toLowerCase() === 'waiting on'){
        const operatorDetails: any = this.operatorList.find((o) => o['appName'] === this.operatorName);
        const waitingOnPayload  ={
          operatorName: operatorDetails['operatorName'],
          nodeID: this.tasq.wellName
        }
        await gptModule.getWaitingOnStatus(waitingOnPayload)
      }

      this.$tasqAlert({
        title: 'Success',
        message: 'Last action successfully updated',
        type: 'success',
      });
    } catch (e) {
      console.log(e);
      this.$tasqAlert({
        title: 'Success',
        message: 'Last action update failed',
        type: 'error',
      });
    } finally {
      this.$emit('close');
    }
  }


  async deleteAiComment(payload) {
    try {
      console.log(payload);

      this.showLastActionModal = false;
      const operatorDetails: any = this.operatorList.find((o) => o['appName'] === this.operatorName);
      await sleep(1000);
      let  wellMetaData = {
        ...(this.operatorName !== 'demo' && {
          operator_name: operatorDetails['operatorName'],
        }),
        ...(this.operatorName !== 'demo' && {
          operator_id: operatorDetails['operatorID'],
        }),
        nodeid: this.tasq.wellName,
        level: this.tasq && this.tasq.level ? this.tasq.level.toLowerCase() : 'well'
      }
      const idData =  payload.id || this.lastActionDetails.id

      await axiosClient.delete(`/ai/actions/${idData}`, {data: wellMetaData});
      gptModule.deleteLastAction(idData);
      gptModule.deleteDefaultLastAction(idData);
      if(this.lastActionDetails.type && this.lastActionDetails.type.toLowerCase() === 'waiting on'){
        const operatorDetails: any = this.operatorList.find((o) => o['appName'] === this.operatorName);
        const waitingOnPayload  ={
          operatorName: operatorDetails['operatorName'],
          nodeID: this.tasq.wellName
        }
        await gptModule.getWaitingOnStatus(waitingOnPayload)
      }

      this.$tasqAlert({
        title: 'Success',
        message: 'Last action deleted successfully',
        type: 'success',
      });
    } catch (e) {
      console.log(e);
      this.$tasqAlert({
        title: 'Success',
        message: 'Last action delete failed',
        type: 'error',
      });
    } finally {
      this.$emit('close');
    }
  }

  async getLastActionDropdownValues(){

    try {

      const payload = {
        "node_names": [this.tasq.wellName],
        "config_type": 'frontend',
        "config_subtype": 'last_actions_dropdown'
      }
      const {data} = await axiosClient.post('/config-store/get_nodes_configs', payload)

      if(data && data.length){
        const result =  data[0]
        const {config_data: {type: lastActionTypes}}  = result
        if(lastActionTypes && Object.keys(lastActionTypes)){
          this.lastActionTypesData = Object.keys(lastActionTypes)
          this.lastActionCateogryData = lastActionTypes
        }
      }

    } catch (error) {
      this.lastActionTypesData = [
        'Waiting on',
        'Setpoint Change',
        'Vendor',
        'On site fix',
        'Alarm',
        'Midstream',
        'Other',
      ]
    }


  }

  destroyed() {
    tasqFeedbackModule.resetAllData();
  }
}
